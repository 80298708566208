import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import styled from "styled-components";
import SEO from "../components/seo";
import ReactPlayer from "react-player";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  ccbiffbamboomRegular,
  mainWhite,
  mainOrange,
  innerWidth,
  montserratRegular,
  darkBlue,
  transHover,
  screen,
  mainGreen,
} from "../components/common/variables";
import ScrollAnimation from "react-animate-on-scroll";
import SlopedLabel from "../components/SlopedLabel";
import Carousel, { Modal, ModalGateway } from "react-images";
import grunge from "../images/about/home-background.jpg";
import VideoModal from "../components/video-modal";
import PersonModal from "../components/person-modal";
import blueBg from "../images/home/the-martin-lysicrates-prize-event.jpeg";
import orangeBg from "../images/MartinLysicrates-2021Event-background-orange.png";

const Wrapper = styled.div`
  .introduction-wrapper {
    background-image: ${props => `url(${props.bg})`};
    background-repeat: no-repeat;
    background-position: 200px auto;
    background-size: cover;
    padding-top: 60px;
    padding-bottom: 60px;
    @media ${screen.small} {
      padding-top: 200px;
      padding-bottom: 80px;
      background-position: center;
    }

    .introduction {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding-left: 30px;
      padding-right: 30px;
      @media ${screen.small} {
        padding-left: 50px;
        padding-right: 50px;
      }

      &__heading {
        color: ${mainWhite};
        font-family: ${ccbiffbamboomRegular};
        font-size: 1.9rem;
        @media ${screen.small} {
          font-size: 4.7rem;
        }
        &--top {
          display: block;
        }

        &--bottom {
          display: block;
          @media ${screen.small} {
            margin-left: 265px;
          }
        }

        &--year {
          font-size: 3rem;
          @media ${screen.small} {
            font-size: 7rem;
          }
        }
      }

      &__subheading {
        font-family: ${ccbiffbamboomRegular};
        color: ${darkBlue};
        font-size: 1.3rem;
        white-space: pre-line;
        margin-top: 20px;
        @media ${screen.small} {
          font-size: 2rem;
          max-width: 50%;
        }
      }

      &__description {
        margin-top: 20px;
        max-width: 600px;
        @media ${screen.small} {
          max-width: 50%;
        }

        p {
          color: ${mainWhite};
          font-family: ${montserratRegular};
          font-size: 1.15rem;
          margin-bottom: 20px;
          @media ${screen.small} {
            font-size: 1.4rem;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .main-wrapper {
    background-image: url(${grunge});
    padding-top: 20px;
    padding-bottom: 100px;

    .full-text-wrapper {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 40px 30px;
      @media ${screen.small} {
        padding: 40px 50px;
      }

      .full-text {
        width: inherit !important;
        @media ${screen.small} {
          width: inherit;
        }
        &__heading {
          color: ${darkBlue};
          font-size: 2rem;
          font-family: ${ccbiffbamboomRegular};
          margin-bottom: 20px;
          white-space: pre-line;

          @media ${screen.small} {
            font-size: 3.5rem;
          }

          .styled-heading {
            margin-left: -3px;
            @media ${screen.small} {
              margin-left: -4px;
            }
            @media ${screen.large} {
              margin-left: 46px;
            }
          }

          span {
            transform: rotateY(-7deg);
            font-size: 1.18rem;
            @media ${screen.small} {
              font-size: 2rem;
            }
            @media ${screen.medium} {
              font-size: 2.4rem;
            }
          }
        }

        &__description {
          p {
            color: ${darkBlue};
            font-family: ${montserratRegular};
            font-weight: 400;
            font-size: 1.05rem;
            margin-bottom: 20px;
            @media ${screen.small} {
              font-size: 1.25rem;
            }
            @media ${screen.large} {
              margin-left: 60px;
            }
          }

          a {
            background: ${mainOrange};
            color: ${mainWhite};
            transition: ${transHover};
            margin: 0 2px;
            &:hover {
              background: none;
              color: ${mainOrange};
            }
          }

          ul,
          ol {
            @media ${screen.large} {
              margin-left: 60px;
            }

            li {
              color: ${darkBlue};
              font-family: ${montserratRegular};
              font-size: 1.05rem;
              margin-bottom: 20px;
              list-style-type: disc;
              list-style-position: inside;
              @media ${screen.small} {
                font-size: 1.25rem;
              }
            }
          }

          ol {
            li {
              list-style-type: decimal;
            }
          }
        }
      }
    }

    .video {
      max-width: ${innerWidth};
      margin: ${props => (props.is2017 ? "-30px auto 30px auto" : "25px auto")};
      padding-left: 30px;
      padding-right: 30px;
      @media ${screen.small} {
        padding-left: 50px;
        padding-right: 50px;
        margin: ${props => (props.is2017 ? "0 auto" : "70px auto")};
      }

      &__heading {
        color: ${darkBlue};
        font-size: 2rem;
        font-family: ${ccbiffbamboomRegular};
        margin-bottom: 20px;
        @media ${screen.small} {
          font-size: 3rem;
        }
      }

      &__wrapper {
        max-width: 1200px;
      }

      &__react-player {
        position: relative;
        padding-top: 56.25%;

        .player {
          position: absolute;
          top: 0;
          left: 0;
          width: 100% !important;
          height: 100% !important;
        }
      }
    }

    .voting-showcase-wrapper {
      .voting-showcase {
        max-width: ${innerWidth};
        margin: 100px auto 0 auto;
        padding-left: 30px;
        padding-right: 30px;
        @media ${screen.small} {
          padding-left: 50px;
          padding-right: 50px;
          margin: 200px auto 0 auto;
        }

        &__heading {
          margin-left: 0;
          @media ${screen.small} {
            margin-left: 126px;
          }
          @media ${screen.medium} {
            margin-left: 183px;
          }
          span {
            font-size: 1.3rem;
            @media ${screen.small} {
              font-size: 2.2rem;
            }
            @media ${screen.medium} {
              font-size: 2.55rem;
            }
          }
        }

        &__subheading {
          color: ${darkBlue};
          margin-top: 30px;
          margin-bottom: -30px;
          font-size: 1.45rem;
          font-family: ${ccbiffbamboomRegular};
          @media ${screen.small} {
            font-size: 2.4rem;
            margin-bottom: 0px;
          }
        }

        &__list {
          display: flex;
          flex-wrap: wrap;
          margin: 60px 0 30px 0;
          flex-direction: column;
          @media ${screen.xsmall} {
            margin: 60px -30px;
            flex-direction: row;
          }
          @media ${screen.small} {
            padding: 0;
          }
          @media ${screen.medium} {
            margin: 100px -30px;
          }

          .showcase-item {
            cursor: pointer;
            width: 100%;
            margin: 0 0 46px 0;
            @media ${screen.small} {
              margin: 0 30px;
              width: calc(33.33% - 60px);
              opacity: 1;
            }

            &__title {
              color: ${darkBlue};
              margin-bottom: 8px;
              font-size: 1.3rem;
              font-family: ${ccbiffbamboomRegular};
              font-style: italic;
              text-align: center;
              margin-top: 20px;
              @media ${screen.small} {
                font-size: 2rem;
              }
            }
            &__player-wrapper {
              position: relative;
              padding-top: 56.25%;
              @media ${screen.medium} {
                padding-top: 56.25%;
              }

              .showcase-player {
                margin-bottom: 50px;
                max-width: 740px;
                width: 100% !important;
                position: absolute;
                top: 0;
                left: 0;
                width: 100% !important;
                height: 100% !important;
                pointer-events: none;

                @media ${screen.small} {
                  margin-bottom: 0;
                }

                .react-player__shadow {
                  display: none !important;

                  .react-player__play-icon {
                    display: none !important;
                  }
                }
              }
            }
          }
        }
      }
    }

    .participants-wrapper {
      &__heading {
        color: ${darkBlue};
        font-family: ${ccbiffbamboomRegular};
        text-align: center;
        font-size: 1rem;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 50px;
        padding-bottom: 50px;
        @media ${screen.small} {
          font-size: 1.7rem;
          padding-left: 0;
          padding-right: 0;
          padding-top: 70px;
          padding-bottom: 70px;
        }
        @media ${screen.large} {
          text-align: right;
          font-size: 2rem;
          padding-top: 160px;
          padding-bottom: 80px;
          padding-right: 100px;
        }
        @media ${screen.xlarge} {
          padding-right: 150px;
        }
      }
      .participants {
        padding: 0 0 70px 0;
        @media ${screen.small} {
          padding: 0 0 75px 0;
        }
        @media ${screen.large} {
          padding: 0 50px 100px 0;
        }

        &__row1 {
          display: flex;
          flex-direction: column;
          padding-left: 30px;
          padding-right: 30px;
          overflow: hidden;
          @media ${screen.small} {
            padding-left: 50px;
            padding-right: 50px;
          }
          @media ${screen.medium} {
            flex-direction: row;
            align-items: flex-start;
          }
          @media ${screen.xlarge} {
            padding-left: 0;
            padding-right: 0;
          }

          .participant-side-img {
            max-width: 550px;
            width: 100%;
            margin-left: -30px;
            @media ${screen.small} {
              max-width: 710px;
              margin-left: -50px;
            }
            @media ${screen.medium} {
              max-width: 550px;
            }
            @media ${screen.large} {
              max-width: 910px;
            }
            @media ${screen.xlarge} {
              margin-left: 0;
            }
          }

          .participant-description {
            max-width: 710px;
            @media ${screen.small} {
              margin-left: 20px;
            }
            @media ${screen.large} {
              margin-left: 50px;
            }
            &__fullname {
              position: relative;
              display: table;
              margin-top: -42px;
              @media ${screen.small} {
                margin-top: -90px;
              }
              @media ${screen.medium} {
                margin-top: 0;
              }
              span {
                font-size: 1.3rem;
                @media ${screen.small} {
                  font-size: 2.35rem;
                }
                @media ${screen.xlarge} {
                  font-size: 3rem;
                }
              }
            }

            &__play-title {
              font-family: ${ccbiffbamboomRegular};
              color: ${darkBlue};
              font-size: 1.9rem;
              white-space: pre-wrap;
              margin: 12px 0;
              @media ${screen.small} {
                font-size: 3.5rem;
                margin: 20px 0;
              }
            }

            &__description {
              p {
                color: ${darkBlue};
                font-family: ${montserratRegular};
                font-weight: 400;
                font-size: 1.05rem;
                margin-bottom: 20px;
                @media ${screen.small} {
                  font-size: 1.25rem;
                }
              }
            }
          }
        }

        &__row2 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          max-width: ${innerWidth};
          margin: 20px auto 0 auto;
          flex-direction: column;
          padding-left: 30px;
          padding-right: 30px;
          @media ${screen.small} {
            padding-left: 50px;
            padding-right: 50px;
          }
          @media ${screen.medium} {
            flex-direction: row;
          }

          .secondary-description {
            max-width: 710px;
            width: 100%;

            &__heading {
              font-family: ${ccbiffbamboomRegular};
              color: ${darkBlue};
              font-size: 1.9rem;
              margin: 12px 0;
              @media ${screen.small} {
                font-size: 3rem;
                margin: 20px 0;
                white-space: pre;
              }
            }

            p {
              color: ${darkBlue};
              font-family: ${montserratRegular};
              font-weight: 400;
              font-size: 1.05rem;
              margin-bottom: 20px;
              @media ${screen.small} {
                font-size: 1.25rem;
              }
            }

            ul,
            ol {
              li {
                color: ${darkBlue};
                font-family: ${montserratRegular};
                font-weight: 400;
                font-size: 1rem;
                margin-bottom: 20px;
                margin-left: 20px;
                @media ${screen.small} {
                  font-size: 1.1rem;
                }
              }
            }
          }

          .quote-and-gallery {
            max-width: 690px;
            width: 100%;
            @media ${screen.medium} {
              margin-left: 40px;
            }
            @media ${screen.xlarge} {
              margin-left: 140px;
            }

            &__quote {
              color: ${mainOrange};
              font-family: ${ccbiffbamboomRegular};
              font-size: 1.7rem;
              font-style: italic;
              line-height: 1.5;
              max-width: 600px;
              margin-bottom: 35px;
              @media ${screen.small} {
                font-size: 2rem;
                margin-bottom: 70px;
              }
              @media ${screen.medium} {
                font-size: 3rem;
              }
            }
          }
        }

        .snaps-gallery {
          .snaps-heading {
            font-family: ${ccbiffbamboomRegular};
            color: ${darkBlue};
            font-size: 1.9rem;
            @media ${screen.small} {
              font-size: 3rem;
              white-space: pre;
            }
          }

          .gallery-list {
            display: flex;
            flex-wrap: wrap;
            margin-top: 12px;
            @media ${screen.small} {
              margin-top: 20px;
            }

            img {
              cursor: pointer;
              width: 64px;
              height: 64px;
              object-fit: cover;
              border: 4px ${mainWhite} solid;
              margin-right: 14px;
              margin-bottom: 14px;
              transition: ${transHover};
              @media ${screen.small} {
                border: 8px ${mainWhite} solid;
                width: 116px;
                height: 116px;
              }
              @media ${screen.large} {
                width: 148px;
                height: 148px;
              }
              &:hover {
                opacity: 0.58;
              }
            }
          }

          &--margin-top {
            margin: 30px 0 0 0;
            @media ${screen.small} {
              margin: 40px 0 0 0;
            }
            @media ${screen.large} {
              margin: 60px 0 0 0;
            }
          }
        }
      }
    }

    .lightbox-list-wrapper {
      .lightbox {
        max-width: ${innerWidth};
        margin: 0 auto 0 auto;
        padding: 70px 30px 30px 30px;
        @media ${screen.small} {
          padding: 100px 50px 170px 50px;
          margin: 0 auto 0 auto;
        }

        .heading {
          span {
            white-space: pre;
          }
        }

        .subheading {
          font-family: ${ccbiffbamboomRegular};
          color: ${mainWhite};
          font-size: 1.9rem;
          margin: 20px 0;
          max-width: 650px;
          @media ${screen.small} {
            font-size: 3rem;
          }
        }

        .lightbox-list {
          display: flex;
          flex-wrap: wrap;
          margin: 45px 0 0 0;
          @media ${screen.small} {
            justify-content: center;
            margin: 60px -28px 0 -28px;
          }

          @media ${screen.large} {
            justify-content: space-between;
          }

          &__each {
            margin: 0 0 78px 0;
            width: 100%;
            @media ${screen.small} {
              width: calc(50% - 56px);
              margin: 0 28px 84px 28px;
            }

            @media ${screen.large} {
              width: calc(25% - 56px);
              margin: 0 28px;
            }

            .img {
              cursor: pointer;
            }

            .name {
              font-family: ${ccbiffbamboomRegular};
              font-size: 1.7rem;
              margin: 15px 0 0 0;
              @media ${screen.small} {
                font-size: 2.27rem;
                margin: 25px 0 0 0;
              }
            }

            .excerpt {
              color: ${mainWhite};
              font-family: ${montserratRegular};
              font-size: 1.22rem;
              margin: 4px 0 0 0;
            }

            .btn {
              all: unset;
              cursor: pointer;

              span {
                transition: ${transHover};
                font-size: 1.5rem;
                @media ${screen.medium} {
                  font-size: 1.8rem;
                }
              }
            }
          }
        }
      }

      &--blue {
        background-image: url(${blueBg});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        .heading {
          margin: 0 0 0 24px;
          @media ${screen.xsmall} {
            margin: 0 0 0 77px;
          }
          @media ${screen.medium} {
            margin: 0 0 0 136px;
          }

          span {
            background: ${mainGreen};
          }
        }

        .lightbox-list {
          .name {
            color: ${mainOrange};
          }

          .excerpt {
            font-weight: 600 !important;
            font-style: italic;
          }

          .btn {
            margin: 16px 0 0 11px !important;
            @media ${screen.small} {
              margin: 28px 0 0 15px !important;
            }

            span {
              background: ${mainGreen};
              @media ${screen.withCursor} {
                &:hover {
                  background: ${mainWhite};
                  color: ${mainGreen};
                }
              }
            }
          }
        }
      }

      &--orange {
        background-image: url(${orangeBg});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        .heading {
          margin: 0 0 0 18px;
          @media ${screen.xsmall} {
            margin: 0 0 0 50px;
          }
          @media ${screen.medium} {
            margin: 0 0 0 90px;
          }

          .sloped {
            span {
              background: ${darkBlue};
            }
          }
        }

        .lightbox-list {
          .name {
            color: ${darkBlue};
          }

          .excerpt {
            font-size: 1.1rem !important;
          }

          .btn {
            margin: 12px 0 0 6px !important;
            @media ${screen.small} {
              margin: 28px 0 0 5px !important;
            }

            span {
              background: ${darkBlue};
              @media ${screen.withCursor} {
                &:hover {
                  background: ${mainWhite};
                  color: ${darkBlue};
                }
              }
            }
          }
        }
      }
    }

    .credits-wrapper {
      .credits {
        max-width: ${innerWidth};
        margin: 0 auto;
        padding: 60px 30px 0 30px;
        @media ${screen.small} {
          padding: 100px 50px;
          margin: 0 auto;
        }

        .heading {
          margin: 0 0 0 18px;
          @media ${screen.small} {
            margin: 0 0 0 100px;
          }
        }

        .credit-list {
          display: flex;
          flex-wrap: wrap;
          color: ${darkBlue};
          margin: 29px 0 0 0;
          @media ${screen.small} {
            margin: 69px -23px 0 -23px;
          }
          @media ${screen.large} {
            padding: 0 50px 0 0;
          }
          @media ${screen.xlarge} {
            padding: 0 100px 0 0;
          }

          &__each {
            width: 100%;
            margin: 0 0 46px 0;
            @media ${screen.small} {
              width: calc(50% - 46px);
              margin: 0 23px 46px 23px;
            }

            .role {
              font-weight: 600 !important;
              font-style: italic;
              font-family: ${montserratRegular};
              font-size: 1.22rem;
              max-width: 320px;
            }

            .name {
              font-family: ${ccbiffbamboomRegular};
              font-size: 1.7rem;
              margin: 0;
              @media ${screen.small} {
                font-size: 2.27rem;
                margin: 10px 0 0 0;
              }
            }
          }
        }
      }
    }

    .gallery-wrapper {
      padding: 50px 0 0 0;
      @media ${screen.small} {
        padding: 55px 0 0 0;
      }
      @media ${screen.large} {
        padding: 80px 0 0 0;
      }

      .gallery {
        max-width: ${innerWidth};
        margin: 0 auto;
        padding-left: 30px;
        padding-right: 30px;
        @media ${screen.small} {
          padding-left: 50px;
          padding-right: 50px;
        }

        &__heading {
          color: ${mainOrange};
          font-family: ${ccbiffbamboomRegular};
          font-size: 1.9rem;
          white-space: pre;
          @media ${screen.small} {
            font-size: 4.5rem;
          }

          .break {
            display: block;
            margin-left: 22px;
            @media ${screen.small} {
              margin-left: 50px;
            }
          }
        }

        &__subheading {
          color: ${darkBlue};
          font-family: ${montserratRegular};
          font-size: 1.05rem;
          margin-top: 14px;
          margin-bottom: 30px;
          white-space: pre;
          font-weight: 700;
          font-style: italic;
          margin-left: 22px;
          @media ${screen.small} {
            font-size: 1.25rem;
            margin-top: 22px;
            margin-left: 100px;
          }
        }

        &__list {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -7px;
          @media ${screen.large} {
            margin: 0 -17px;
          }

          li {
            width: calc(25% - 14px);
            margin: 7px;
            transition: ${transHover};
            @media ${screen.small} {
              width: calc(16.66% - 14px);
            }
            @media ${screen.large} {
              width: calc(12.5% - 34px);
              margin: 17px;
            }

            img {
              cursor: pointer;
              width: 100%;
              display: block;
              border: 4px ${mainWhite} solid;
              @media ${screen.small} {
                border: 8px ${mainWhite} solid;
              }
            }

            &:hover {
              opacity: 0.58;
            }
          }
        }

        &__more-btn {
          margin-top: 16px;
          display: flex;
          justify-content: flex-end;

          button {
            background: ${darkBlue};
            border: 0;
            border-radius: 0;
            cursor: pointer;
            font-size: 1.25rem;
            color: ${mainWhite};
            font-family: ${ccbiffbamboomRegular};
            transition: ${transHover};
            outline-color: ${mainOrange};
            padding: 11px 19px;
            @media ${screen.small} {
              font-size: 1.5rem;
              margin-top: 24px;
              padding: 13px 20px;
            }
            @media ${screen.withCursor} {
              &:hover {
                background: ${mainOrange};
              }
            }
          }
        }
      }
    }
  }
`;

const SingleEvent = ({ data }) => {
  const [person, setPerson] = useState(null);
  const [isModalOpen, setModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentSnaps, setCurrentSnaps] = useState([{ src: "" }]);
  const [isVideoOpen, setVideo] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");
  const [imgListLength, setImgListLength] = useState(16);
  const { content } = data;
  const {
    title,
    subheading,
    title_tag,
    meta_description,
    background_image,
    description,
    body,
  } = content.data;

  const is2021Event = title.text === "2021";

  const openImgModal = (index, images) => {
    setModal(true);
    setCurrentSnaps(images);
    console.log(images)
    setCurrentIndex(index);
  };

  const closeImgModal = () => {
    setModal(false);
    setCurrentIndex(false);
  };

  const openVidModal = url => {
    setSelectedVideo(url);
    setVideo(true);
  };

  const closeVidModal = () => {
    setSelectedVideo("");
    setVideo(false);
  };

  const expandGalleryList = () => {
    setImgListLength(imgListLength + 16);
  };

  useEffect(() => {
    const userClick = e => {
      // close image modal when user has clicked outside of it
      if (
        typeof e.target.className === "string" &&
        e.target.className.includes("react-images")
      )
        closeImgModal();
    };
    window.addEventListener("click", userClick);

    return () => {
      window.removeEventListener("click", userClick);
    };
  }, []);

  console.log(content.items)

  // Prismic slices to render
  const renderRows =
    body &&
    body.map(content => {
      // html for regular text slice
      if (content.slice_type === "full_text") {
        return (
          <div className="full-text-wrapper" key={content.id}>
            <div
              className="full-text"
              style={{
                maxWidth: content.primary.width === `50%` ? `735px` : `inherit`,
              }}
            >
              {content.primary.heading && (
                <h2 className="full-text__heading">
                  {content.primary.coloured_heading ? (
                    <SlopedLabel
                      className="styled-heading"
                      text={content.primary.heading}
                    />
                  ) : (
                    content.primary.heading
                  )}
                </h2>
              )}
              <div
                className="full-text__description"
                dangerouslySetInnerHTML={{
                  __html: content.primary.description1.html,
                }}
              ></div>
            </div>
          </div>
        );
      }

      // html for video slice
      if (content.slice_type === "video") {
        return (
          <div key={content.id} className="video">
            <h2 className="video__heading">{content.primary.heading}</h2>
            <div className="video__wrapper">
              <div className="video__react-player">
                <ReactPlayer
                  className="player"
                  url={content.primary.url}
                  controls={true}
                />
              </div>
            </div>
          </div>
        );
      }

      // html for voting showcase slice
      if (content.slice_type === "voting_showcase") {
        return (
          <div key={content.id} className="voting-showcase-wrapper">
            <div className="voting-showcase">
              <h2 className="voting-showcase__heading">
                <SlopedLabel text={content.primary.heading} />
              </h2>
              <p className="voting-showcase__subheading">
                {content.primary.subheading1}
              </p>
              <div className="voting-showcase__list">
                {content.items.map((item, i) => (
                  <div
                    className="showcase-item"
                    key={i}
                    onClick={() => openVidModal(item.video_url)}
                  >
                    <div className="showcase-item__player-wrapper">
                      <ReactPlayer
                        className="showcase-player"
                        url={item.video_url}
                        // light={true}
                      />
                    </div>
                    <h4 className="showcase-item__title"> {item.play_title}</h4>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );
      }

      // html for participant slice
      if (content.slice_type === "participants") {
        return (
          <div className="participants-wrapper" key={content.id}>
            <h2 className="participants-wrapper__heading">
              MEET OUR INCREDIBLE PLAYWRIGHTS!
            </h2>
            {content.items.map((item, i) => (
              <div className="participants" key={i}>
                <div className="participants__row1">
                  <ScrollAnimation
                    className="participant-side-img"
                    animateIn="fadeInLeft"
                    animateOnce={true}
                  >
                    <div>
                      {item.participant.document.data.image.gatsbyImageData && (
                        <GatsbyImage
                          image={
                            item.participant.document.data.image.gatsbyImageData
                          }
                          alt="Participant"
                        />
                      )}
                    </div>
                  </ScrollAnimation>
                  <div className="participant-description">
                    <h2 className="participant-description__fullname">
                      <SlopedLabel
                        // remove number from participant name by regex
                        text={item.participant.document.data.full_name.text.replace(
                          /[^a-zA-Z\s]+/g,
                          ""
                        )}
                      />
                    </h2>
                    <h3 className="participant-description__play-title">
                      {item.participant.document.data.play_title}
                    </h3>
                    <div
                      className="participant-description__description"
                      dangerouslySetInnerHTML={{
                        __html: item.participant.document.data.description.html,
                      }}
                    />
                    {is2021Event &&
                      item.participant.document.data.gallery.length !== 0 && (
                        <div className="snaps-gallery snaps-gallery--margin-top">
                          <h3 className="snaps-heading">Some snaps!</h3>
                          <div className="gallery-list">
                            {item.participant.document.data.gallery.map(
                              (img, i) => (
                                <img
                                  key={i}
                                  src={img.image_gallery.url}
                                  alt="Snap gallery"
                                  onClick={() =>
                                    openImgModal(
                                      i,
                                      // change array of image with src property
                                      item.participant.document.data.gallery.map(
                                        photo => {
                                          return {
                                            src: photo.image_gallery.url,
                                          };
                                        }
                                      )
                                    )
                                  }
                                />
                              )
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                {item.participant.document.data.description1.html ||
                item.participant.document.data.gallery ||
                item.participant.document.data.quote ? (
                  <div className="participants__row2">
                    <div className="secondary-description">
                      <h2 className="secondary-description__heading">
                        {item.participant.document.data.heading}
                      </h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            item.participant.document.data.description1.html,
                        }}
                      />
                    </div>
                    <div className="quote-and-gallery">
                      {item.participant.document.data.quote && (
                        <div className="quote-and-gallery__quote">
                          “…{item.participant.document.data.quote}“
                        </div>
                      )}

                      {!is2021Event &&
                        item.participant.document.data.gallery.length !== 0 && (
                          <div className="snaps-gallery">
                            <h3 className="snaps-heading">Some snaps!</h3>
                            <div className="gallery-list">
                              {item.participant.document.data.gallery.map(
                                (img, i) => (
                                  <img
                                    key={i}
                                    src={img.image_gallery.url}
                                    alt="Snap gallery"
                                    onClick={() =>
                                      openImgModal(
                                        i,
                                        // change array of image with src property
                                        item.participant.document.data.gallery.map(
                                          photo => {
                                            return {
                                              src: photo.image_gallery.url,
                                            };
                                          }
                                        )
                                      )
                                    }
                                  />
                                )
                              )}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        );
      }

      // html for image gallery slice
      if (content.slice_type === "image_gallery") {
        return (
          <div className="gallery-wrapper" key={content.id}>
            <div className="gallery">
              <h2 className="gallery__heading">
                A FEW PICS <span className="break">FROM THE DAY!</span>
              </h2>
              <p className="gallery__subheading">
                Take a look at some of the photos
                <br />
                taken from this incredible day!
              </p>

              <ul className="gallery__list">
                {content.items.slice(0, imgListLength).map((img, i) => (
                  <li
                    key={i}
                    onClick={() =>
                      openImgModal(
                        i,
                        // change array of image with src property
                        content.items.map(photo => {
                          return {
                            src: photo.image.url,
                          };
                        })
                      )
                    }
                  >
                    <div>
                      {img.image.thumbnails.thumbnail && (
                        <img
                          src={img.image.thumbnails.thumbnail.url}
                          alt="gallery"
                        />
                      )}
                    </div>
                  </li>
                ))}
              </ul>
              {content.items.length - 1 >= imgListLength && (
                <div className="gallery__more-btn">
                  <button onClick={expandGalleryList}>Load more</button>
                </div>
              )}
            </div>
          </div>
        );
      }

      // html for lightbox slice
      if (content.slice_type === "lightbox_list") {
        return (
          <div
            className={
              content.primary.colour_theme === "Blue"
                ? "lightbox-list-wrapper lightbox-list-wrapper--blue"
                : "lightbox-list-wrapper lightbox-list-wrapper--orange"
            }
            key={content.id}
          >
            <div className="lightbox">
              <h3 className="heading">
                <SlopedLabel text={content.primary.lightbox_heading.text} />
              </h3>

              <h4 className="subheading">
                {content.primary.lightbox_subheading.text}
              </h4>
              <div className="lightbox-list">
                {content.items.map((item, i) => (
                  <ScrollAnimation
                    className="lightbox-list__each"
                    animateIn={
                      content.primary.colour_theme === "Blue"
                        ? "fadeInRight"
                        : "fadeInLeft"
                    }
                    animateOnce={true}
                    key={i}
                  >
                    <div
                      onClick={() => {
                        setPerson({
                          name: item.name.text,
                          excerpt: item.excerpt,
                          description: item.description1,
                          image: item.image.gatsbyImageData,
                          theme: content.primary.colour_theme,
                        });
                      }}
                    >
                      <div className="img">
                        <GatsbyImage
                          image={item.image.gatsbyImageData}
                          alt={item.image.alt || "Bio"}
                        />
                      </div>
                      <h5 className="name">{item.name.text}</h5>
                      <p className="excerpt">{item.excerpt}</p>

                      <button className="btn">
                        <SlopedLabel
                          text={
                            content.primary.colour_theme === "Blue"
                              ? "TELL ME MORE"
                              : "READ MORE"
                          }
                        />
                      </button>
                    </div>
                  </ScrollAnimation>
                ))}
              </div>
            </div>
          </div>
        );
      }

      if (content.slice_type === "credits") {
        return (
          <div className="credits-wrapper" key={content.id}>
            <div className="credits">
              <h4 className="heading">
                <SlopedLabel text={content.primary.credits_heading.text} />
              </h4>
              <ul className="credit-list">
                {content.items.map((item, i) => (
                  <li className="credit-list__each" key={i}>
                    <p className="role">{item.role}</p>
                    <h6 className="name">{item.name.text}</h6>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        );
      }
    });

  return (
    <Layout>
      <SEO title={title_tag} description={meta_description} />
      <Wrapper bg={background_image.url} is2017={title.text === "2017"}>
        <div className="introduction-wrapper">
          <div className="introduction">
            <h1 className="introduction__heading">
              <span className="introduction__heading--top">
                THE{" "}
                <span className="introduction__heading--year">
                  {title.text}
                </span>
              </span>
              <span className="introduction__heading--bottom">EVENT!</span>
            </h1>
            <h2 className="introduction__subheading">{subheading}</h2>
            <div
              className="introduction__description"
              dangerouslySetInnerHTML={{ __html: description.html }}
            />
          </div>
        </div>

        <main className="main-wrapper">{renderRows}</main>
        {isVideoOpen && (
          <VideoModal url={selectedVideo} closeVidModal={closeVidModal} />
        )}
        <ModalGateway>
          {isModalOpen && (
            <Modal onClose={closeImgModal}>
              <Carousel views={currentSnaps} currentIndex={currentIndex} />
            </Modal>
          )}
        </ModalGateway>

        {person && <PersonModal person={person} setPerson={setPerson} />}
      </Wrapper>
    </Layout>
  );
};

export default SingleEvent;

export const dataQuery = graphql`
  query($id: String!) {
    content: prismicEventYear(id: { eq: $id }) {
      data {
        title_tag
        meta_description
        title {
          text
        }
        subheading
        background_image {
          alt
          url
        }
        description {
          html
        }
        body {
          __typename
          ... on PrismicEventYearDataBodyCredits {
            id
            slice_type
            primary {
              credits_heading {
                text
              }
            }
            items {
              role
              name {
                text
              }
            }
          }
          ... on PrismicEventYearDataBodyLightboxList {
            id
            slice_type
            primary {
              colour_theme
              lightbox_heading {
                text
              }
              lightbox_subheading {
                text
              }
            }
            items {
              description1 {
                html
              }
              excerpt
              name {
                text
              }
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          ... on PrismicEventYearDataBodyFullText {
            id
            slice_type
            primary {
              heading
              coloured_heading
              width
              description1 {
                html
              }
            }
          }
          ... on PrismicEventYearDataBodyImageGallery {
            id
            slice_type
            items {
              image {
                url
                alt
                thumbnails {
                  thumbnail {
                    url
                  }
                }
              }
            }
          }
          ... on PrismicEventYearDataBodyParticipants {
            id
            slice_type
            items {
              participant {
                document {
                  __typename
                  ... on PrismicParticipant {
                    data {
                      full_name {
                        text
                      }
                      image {
                        gatsbyImageData(layout: FULL_WIDTH)
                      }
                      play_title
                      description {
                        html
                      }
                      gallery {
                        image_gallery {
                          url
                        }
                      }
                      winner_
                      heading
                      description {
                        html
                      }
                      description1 {
                        html
                      }
                      quote
                    }
                  }
                }
              }
            }
          }
          ... on PrismicEventYearDataBodyVideo {
            id
            slice_type
            primary {
              url
              heading
            }
          }
          ... on PrismicEventYearDataBodyVotingShowcase {
            id
            slice_type
            primary {
              heading
              subheading1
            }
            items {
              play_title
              video_url
            }
          }
        }
      }
    }
  }
`;
